import { inject, Injectable } from "@angular/core";
import { ICustomProperties, ITelemetryItem } from "@microsoft/applicationinsights-web";
import {
    LG_APP_CONFIGURATION,
    LgBackendApplicationInsightsConfiguration
} from "@logex/framework/lg-application";
import { AppConfiguration } from "./app-startup.service.types";

@Injectable({ providedIn: "root" })
export class ExtendedLgBackendApplicationInsightsConfiguration extends LgBackendApplicationInsightsConfiguration {
    configuration = inject(LG_APP_CONFIGURATION) as AppConfiguration;
    telemetryInitializers: Array<(item: ITelemetryItem) => boolean | void> = [];

    constructor() {
        super();
        this.telemetryInitializers.push((envelope: ITelemetryItem): boolean | void => {
            if (envelope && envelope.tags) {
                envelope.tags["ai.cloud.role"] =
                    this.configuration.full?.applicationInsights?.cloudRoleName ?? "";
                envelope.tags["ai.cloud.roleInstance"] =
                    this.configuration.full?.applicationInsights?.cloudRoleInstance ?? "";
            }
        });
    }

    customData(): ICustomProperties {
        return {
            ...super.customData(),
            ["region"]: this.configuration.full?.applicationInsights?.region ?? ""
        };
    }
}
